import React from 'react';

const Terms = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Terms of Service</h1>
      <p style={styles.text}>
        Welcome to Giggy, a service provided by GRQ Digital Capital LLC. By using our services, you agree to comply with the following terms of service (“Terms”), along with our Privacy Policy and any applicable supplemental terms. Please review these carefully before using our services.
      </p>

      <h2 style={styles.subTitle}>1. Agreement</h2>
      <p style={styles.text}>
        By using Giggy's website, applications, or any related services, you agree to be bound by these Terms, including mandatory arbitration provisions. Your continued use of our services indicates acceptance of these Terms and any future changes.
      </p>

      <h2 style={styles.subTitle}>2. Eligibility</h2>
      <p style={styles.text}>
        You must be at least 18 years old or the legal age of majority in your region to use Giggy services. If you are under the age of 18, you may only use Giggy services with the consent of a parent or legal guardian.
      </p>

      <h2 style={styles.subTitle}>3. Account Use and Security</h2>
      <p style={styles.text}>
        To access certain features of our services, you may need to register an account. You must provide accurate and current information when creating your account. It is your responsibility to maintain the security and confidentiality of your account credentials, and you agree not to share them with others. You must notify Giggy immediately of any unauthorized use of your account or security breach.
      </p>

      <h2 style={styles.subTitle}>4. Service Usage</h2>
      <p style={styles.text}>
        You agree to use Giggy's services in compliance with all applicable laws and regulations. You may not:
        <ul>
          <li>Use the services for illegal activities, unsolicited advertising, or any harmful or offensive content.</li>
          <li>Engage in activities such as reverse engineering, reselling, or exploiting the services for commercial gain without Giggy's permission.</li>
          <li>Upload content that violates the intellectual property rights or privacy of others.</li>
        </ul>
      </p>

      <h2 style={styles.subTitle}>5. Content Ownership and Licensing</h2>
      <p style={styles.text}>
        You retain ownership of the content you input into Giggy's services and any outputs generated. However, by using Giggy, you grant us a worldwide, royalty-free, non-exclusive, perpetual license to use, store, reproduce, and improve your input and output for the purpose of enhancing our services, including AI development.
        Free-tier users are required to credit Giggy when using outputs generated by our services for any external use.
      </p>

      <h2 style={styles.subTitle}>6. Accuracy of AI Outputs</h2>
      <p style={styles.text}>
        Giggy strives to provide accurate and useful AI-generated outputs. However, we cannot guarantee the accuracy, reliability, or suitability of the outputs. You are solely responsible for evaluating the accuracy of any outputs before using them, and you assume full responsibility for how they are used.
      </p>

      <h2 style={styles.subTitle}>7. Paid Services and Subscriptions</h2>
      <p style={styles.text}>
        Certain Giggy services require a paid subscription. By subscribing, you agree to:
        <ul>
          <li>Provide accurate billing information.</li>
          <li>Accept that subscriptions renew automatically unless canceled.</li>
          <li>Payments made for subscriptions, purchases, and services are nonrefundable unless otherwise determined by Giggy.</li>
          <li>Unused credits may expire after one year from the date of issuance.</li>
        </ul>
      </p>

      <h2 style={styles.subTitle}>8. Content Storage</h2>
      <p style={styles.text}>
        Giggy will store user-generated content, including inputs and outputs, for a maximum of six months unless otherwise required by law. After this period, your content may be deleted.
      </p>

      <h2 style={styles.subTitle}>9. Modifications to Services</h2>
      <p style={styles.text}>
        Giggy reserves the right to modify, suspend, or discontinue any aspect of our services at any time without notice. We will not be liable for any such modifications or interruptions.
      </p>

      <h2 style={styles.subTitle}>10. Termination of Services</h2>
      <p style={styles.text}>
        Giggy reserves the right to suspend or terminate your account at any time, especially in cases where you violate these Terms or fail to comply with legal requirements. Termination may also occur due to prolonged inactivity.
      </p>

      <h2 style={styles.subTitle}>11. Warranties and Disclaimers</h2>
      <p style={styles.text}>
        Giggy services are provided "as is" and without any warranties or guarantees of any kind. We do not guarantee uninterrupted access or error-free outputs. You understand and agree that using our services is at your own risk.
      </p>

      <h2 style={styles.subTitle}>12. Limitation of Liability</h2>
      <p style={styles.text}>
        In no event shall Giggy’s liability exceed the amount you have paid for the services in the past 12 months or $100, whichever is lesser. Giggy will not be liable for any indirect, incidental, or consequential damages arising from your use of the services.
      </p>

      <h2 style={styles.subTitle}>13. Indemnification</h2>
      <p style={styles.text}>
        You agree to defend, indemnify, and hold harmless Giggy, its affiliates, and employees from any claims, damages, or liabilities arising from your use of the services, violation of these Terms, or infringement on the rights of any third party.
      </p>

      <h2 style={styles.subTitle}>14. Copyright and Intellectual Property</h2>
      <p style={styles.text}>
        <strong>User-Generated Content:</strong> Users must have the necessary rights to any content they upload or reference. They confirm that they have the legal authority to use and modify existing thumbnails (which may be copyrighted) when using our service.<br/>
        <strong>No Copyright Infringement:</strong> Users are prohibited from using the service to infringe upon the copyrights of third parties. They must not upload or generate content based on copyrighted material unless they have proper authorization.<br/>
        <strong>Fair Use:</strong> Users should consult legal counsel to determine whether their use of YouTube thumbnails constitutes "fair use." Our service does not make legal determinations on fair use.<br/>
        <strong>Responsibility for Usage:</strong> Users are fully responsible for how they use the generated thumbnails, and Giggy is not liable for any copyright infringement or legal consequences arising from their use.
      </p>

      <h2 style={styles.subTitle}>15. Disclaimer of Liability</h2>
      <p style={styles.text}>
        <strong>No Liability for Legal Violations:</strong> Our service is not responsible for any legal issues arising from the misuse of thumbnails, including copyright infringement or violations of social media platform policies.<br/>
        <strong>Third-Party Content:</strong> Giggy disclaims liability for the legality of content from third-party sources. Users should ensure that their use complies with all applicable laws and platform terms.
      </p>

      <h2 style={styles.subTitle}>16. DMCA Compliance</h2>
      <p style={styles.text}>
        <strong>DMCA Takedown Policy:</strong> We comply with the Digital Millennium Copyright Act (DMCA). Copyright holders can report infringing content via hello@giggy.ai. We will respond by removing infringing content and terminating accounts of repeat offenders.
      </p>

      <h2 style={styles.subTitle}>17. Prohibited Use</h2>
      <p style={styles.text}>
        <strong>Misuse of Service:</strong> Users are prohibited from using our service to create thumbnails that impersonate or copy existing copyrighted thumbnails, or for illegal or unethical purposes.<br/>
        <strong>Respect for Platform Policies:</strong> Users must respect the terms of service and community guidelines of any platform when using Giggy. We disclaim liability for any violations.
      </p>

      <h2 style={styles.subTitle}>18. User Liability for Content</h2>
      <p style={styles.text}>
        <strong>Indemnification:</strong> Users agree to indemnify and hold Giggy harmless for any claims, damages, or expenses arising from their use of the service, particularly in cases of copyright or intellectual property disputes.
      </p>

      <h2 style={styles.subTitle}>19. Modifications and Termination</h2>
      <p style={styles.text}>
        <strong>Right to Modify or Remove Content:</strong> Giggy reserves the right to modify or remove any generated content that violates these Terms or legal obligations, including copyright infringement.<br/>
        <strong>Termination of Service:</strong> We reserve the right to suspend or terminate the service for users who engage in illegal or infringing activities.
      </p>

      <h2 style={styles.subTitle}>20. Arbitration Opt-Out</h2>
      <p style={styles.text}>
        You have the right to opt out of the arbitration clause within 30 days of accepting these Terms. To opt out, you must send written notice to Giggy, stating your intention to opt out of mandatory arbitration.
      </p>

      <h2 style={styles.subTitle}>21. Views Disclaimer</h2>
      <p style={styles.text}>
        The content generated through Giggy's services does not represent the views, opinions, or attitudes of Giggy. Users are responsible for how they use the generated content and agree to use it responsibly and kindly.
      </p>

      <h2 style={styles.subTitle}>22. Governing Law</h2>
      <p style={styles.text}>
        These Terms are governed by and construed in accordance with the laws of the jurisdiction where GRQ Digital Capital LLC is registered, without regard to its conflict of law provisions.
      </p>

      <p style={styles.text}>
        If you have any questions regarding these Terms, please contact us at <a href="mailto:support@giggy.ai">support@giggy.ai</a>.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif'
  },
  title: {
    fontSize: '2em',
    marginBottom: '20px'
  },
  subTitle: {
    fontSize: '1.2em',
    marginTop: '20px',
    marginBottom: '20px',
  },
  text: {
    fontSize: '1em',
    lineHeight: '1.6'
  }
};

export default Terms;
