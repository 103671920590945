import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAPHkE-m2ADhOK7jSoWm8t-V1Vf9yQZRFc",
  authDomain: "giggy-11b33.firebaseapp.com",
  projectId: "giggy-11b33",
  storageBucket: "giggy-11b33.appspot.com",
  messagingSenderId: "520688814620",
  appId: "1:520688814620:web:40fabb61c394f77f48edd9",
  measurementId: "G-7BFL5XD8J3"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
