import React, { useEffect, useMemo, useState, useRef } from 'react';
import './LoadingDelight.css';
import { useSelector } from 'react-redux';

const LoadingDelight = () => {
  // Add a ref to the message element
  const messageRef = useRef(null);

  // Your default delights and state management
  const defaultDelights = useMemo(() => [
    "Paws-itive vibes coming your way!",
    "I'm unleashing something special...",
    "Sit. Stay. Loading...",
    "Sniffing out the data...",
    "Wagging my tail in excitement",
    "Howl about a little patience?",
    "Paw-sist, I'm almost ready!",
    "Our servers are on a roll(over)!",
    "Chews-ing goodies just for you...",
    "Don’t furrr-get, good things take time!",
    "On the hunt...",
    "I'm on it!",
    "Who needs AI when you have man's best friend?",
    "Fetching some magic...",
    "Hang in there, magic incoming!",
    "Preparing something awesome, just for you!",
    "Busy being a good boy...",
    "Almost ready, hang on tight!",
    "Charging up creativity... nearly there!",
    "Good things take time… almost there!",
  ], []);

  const [delightMessages, setDelightMessages] = useState(defaultDelights);
  const loadingDelightType = useSelector((state) => state.user.loadingDelightType);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const characterDelights = [
      'Getting to know you...',
      'Tidying up your photo...',
      'Making you look like a YouTuber...',
    ];

    const brainstormDelights = [
      'Sniffing out the best ideas...',
      'Fetching some creativity...',
      'Chasing down the perfect design...',
      'Digging for inspiration...',
      'Wagging tails and working hard...',
      'Barking up the right tree of ideas...',
      'Howling with creativity...',
      'Tugging at the leash of inspiration...',
      'Sniffing out what will grab attention...',
    ];

    let selectedDelights = defaultDelights; // Fallback to default
    if (loadingDelightType === 'character') {
      selectedDelights = characterDelights;
    } else if (loadingDelightType === 'premiseCaptionBrainstorm') {
      selectedDelights = brainstormDelights;
    }
    setDelightMessages(selectedDelights);

    // Ensure message index is valid after the array changes
    if (selectedDelights.length > 0) {
      setCurrentMessageIndex(Math.floor(Math.random() * selectedDelights.length));
    }
  }, [loadingDelightType]);

  // Attach the animationiteration event listener
  useEffect(() => {
    const handleAnimationIteration = () => {
      if (delightMessages.length > 1) {
        setCurrentMessageIndex((prevIndex) => {
          let nextIndex;
          do {
            nextIndex = Math.floor(Math.random() * delightMessages.length);
          } while (nextIndex === prevIndex);
          return nextIndex;
        });
      }
    };

    const messageElement = messageRef.current;
    if (messageElement) {
      messageElement.addEventListener('animationiteration', handleAnimationIteration);
    }

    // Clean up the event listener on unmount
    return () => {
      if (messageElement) {
        messageElement.removeEventListener('animationiteration', handleAnimationIteration);
      }
    };
  }, [delightMessages]);

  return (
    <div className="loading-container">
      <div className="loading-message" ref={messageRef}>
        {delightMessages[currentMessageIndex]}
      </div>
    </div>
  );
};

export default LoadingDelight;
